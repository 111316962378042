import platforms from "../platforms";

export const InlinePlatformSelector = ({
  selectedPlatformId,
  setSelectedPlatformId,
}) => {
  const TEXT = "Veuillez sélectionner un programme";
  const onClickHandler = (e) => {
    const platformId =
      e.target.closest("[data-platform-id]").dataset.platformId;
    setSelectedPlatformId(platformId);
  };
  return (
    <div className="flex flex-col">
      <div className="flex justify-start">
        <p className="text-sm text-gray-500 italic">{TEXT}</p>
      </div>
      <div
        onClick={onClickHandler}
        className="flex justify-center items-center"
      >
        <div
          id="borderDiv"
          className="flex flex-row w-full justify-between rounded-xl border-2 border-gray-300 overflow-hidden"
        >
          <PlatFormCard
            platformId={platforms.com.id}
            isSelected={selectedPlatformId === platforms.com.id}
          />
          <PlatFormCard
            platformId={platforms.mons.id}
            isSelected={selectedPlatformId === platforms.mons.id}
          />
        </div>
      </div>
    </div>
  );
};

// Make a variant version of the selector to be included in the header as a select field
export const HeaderPlatformSelector = ({
  selectedPlatformId,
  setSelectedPlatformId,
}) => {
  const isMobile = window.innerWidth < 420;

  return (
    <select
      className="text-clientBlue p-1 border border-opacity-50 border-clientBlue rounded-lg"
      value={selectedPlatformId}
      onChange={(e) => setSelectedPlatformId(e.target.value)}
    >
      <option value={platforms.com.id}>
        {isMobile ? platforms.com.id : platforms.com.title}
      </option>
      <option value={platforms.mons.id}>
        {isMobile ? platforms.mons.id : platforms.mons.title}
      </option>
    </select>
  );
};

const PlatFormCard = ({ isSelected, platformId }) => {
  const { src, title } = platforms[platformId];
  return (
    <div
      data-platform-id={platformId}
      className={`
        w-1/2
        select-none
        cursor-pointer
        pointer-events-all
        flex flex-col items-center justify-center p-4
        rounded-lg
        ${isSelected ? "bg-clientBlue" : "white"} max-w-sm overflow-hidden`}
    >
      <img
        className="h-10 w-auto md:h-20 pointer-events-none"
        src={src}
        alt={title}
      />
      <p
        className={`${
          isSelected ? "text-white" : "text-gray-700"
        } text-sm pointer-events-none`}
      >
        {title}
      </p>
    </div>
  );
};
