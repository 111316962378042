import React, { useState, useEffect } from "react";
import LanguageSelector from "./LanguageSelector";
import axios from "axios";
import Cookies from "js-cookie";
import { getChatID } from "./chatIDModule";
import { setChatID } from "./chatIDModule";
import { HeaderPlatformSelector } from "./PlatformSelector";

const ChatHeader = ({
  onAudioClick,
  onLanguageChange,
  chatId,
  deletechatID,
  selectedPlatformId,
  setSelectedPlatformId,
}) => {
  const [isAudioOn, setIsAudioOff] = useState(false);
  const fetchDownload = async () => {
    try {
      // Utilisez onDownloadClick pour obtenir les informations nécessaires, par exemple l'ID de la conversation
      // console.log(chatId)
      const response = await axios.post(
        "",
        {
          ChatID: chatId,
          auth_key: "VSoHghJ~l4g&xNr~{YHgy^Xe$w6Jb6UG=yv,!Y-GgkEtRRB7LL",
        },
        { responseType: "blob" } // Indiquez à Axios que la réponse est un fichier
      );

      // Créez un objet blob à partir de la réponse
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Créez un objet URL pour le blob
      const url = window.URL.createObjectURL(blob);

      // Créez un lien temporaire pour déclencher le téléchargement
      const a = document.createElement("a");
      a.href = url;
      a.download = "conversation.pdf";
      document.body.appendChild(a);
      a.click();

      // Libérez l'URL de l'objet blob après le téléchargement
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions :", error);
      // Gérer les erreurs d'API
    }
  };

  const toggleAudioSelector = () => {
    setIsAudioOff((prev) => !prev);
    onAudioClick(isAudioOn);
  };
  useEffect(() => {
    onAudioClick(isAudioOn); // Appelle la fonction de rappel avec la nouvelle langue
    // Appelle la nouvelle fonction de rappel
  }, [isAudioOn, onAudioClick]);
  const handleLanguageChange = (selectedLanguage) => {
    onLanguageChange(selectedLanguage);
  };
  const handleDeleteCookies = () => {
    // Supprimez le cookie en utilisant la clé spécifique que vous avez utilisée
    Cookies.remove("ChatID");
    setChatID(undefined);
    deletechatID(true);
    // Ajoutez ici toute autre logique que vous souhaitez exécuter après la suppression du cookie
    // console.log('Cookies supprimés avec succès.');
  };

  return (
    <div className="flex justify-between items-center absolute top-0 left-0 my-1 w-full pr-5 pl-3">
      <div className="flex items-center gap-2 ">
        <LanguageSelector onLanguageChange={handleLanguageChange} />
        <HeaderPlatformSelector
          selectedPlatformId={selectedPlatformId}
          setSelectedPlatformId={setSelectedPlatformId}
        />
      </div>
      {/* Bouton Télécharger Conversation */}
      <div className="flex">
        {/* <button
					onClick={fetchDownload}
					className=' text-gray-400  ml-2 flex items-center text-xs hover:underline max-sm:hidden'>
					<svg
						width='13'
						height='13'
						viewBox='0 0 13 13'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className='mr-2'>
						<g clipPath='url(#clip0_1_338)'>
							<path
								d='M11.375 8.125V10.2917C11.375 10.579 11.2609 10.8545 11.0577 11.0577C10.8545 11.2609 10.579 11.375 10.2917 11.375H2.70833C2.42102 11.375 2.14547 11.2609 1.9423 11.0577C1.73914 10.8545 1.625 10.579 1.625 10.2917V8.125'
								stroke='#B0B0B0'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M3.7915 5.41675L6.49984 8.12508L9.20817 5.41675'
								stroke='#B0B0B0'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M6.5 8.125V1.625'
								stroke='#B0B0B0'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</g>
						<defs>
							<clipPath id='clip0_1_338'>
								<rect
									width='13'
									height='13'
									fill='white'
								/>
							</clipPath>
						</defs>
					</svg>
					Download conversation
				</button> */}
        <button
          onClick={handleDeleteCookies}
          className="text-gray-400  mx-3 text-xs border-2 p-2 rounded-lg "
        >
          Reset
        </button>
        <img
          src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/mr+logo+(3).png"
          alt=""
          style={{
            width: "auto",
            height: "32px",
            objectFit: "contain",
            marginTop: "0.5px",
          }}
          className="max-sm:hidden"
        />
      </div>
    </div>
  );
};

export default ChatHeader;
