// ChatBox.js

import React, { useRef, useEffect, useState } from "react";
import ChatMessage from "./ChatMessage";
import Welcome from "./Welcome";

const ChatBox = ({
  messages,
  audioOn,
  selectedLanguage,
  onDataFromChild,
  handleIsWriting,
  loading,
  history,
  selectedPlatformId,
  setSelectedPlatformId,
}) => {
  const chatBoxRef = useRef(null);

  const getLastUserMessage = (messages, currentIndex) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (messages[i].isUser) {
        return messages[i];
      }
    }
    return null; // Aucun message d'utilisateur précédent
  };
  useEffect(() => {
    // Faites défiler vers le bas lorsque de nouveaux messages arrivent
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    // Mettez à jour l'état pour afficher Welcome après un délai
  }, [messages, handleIsWriting]);

  const [loaderText, setloaderText] = useState("Je cherche dans mes sources");

  useEffect(() => {
    // Mettez en place une logique pour changer le texte des boutons et du loader en fonction de la langue sélectionnée
    switch (selectedLanguage) {
      case "en":
        setloaderText("Searching in my sources");
        break;
      case "de":
        setloaderText("Ich suche in meinen Quellen");
        break;
      case "nl":
        setloaderText("Aan het zoeken in mijn bronnen");
        break;
      case "fr":
        setloaderText("Je cherche dans mes sources");
        break;
      default:
        setloaderText("Je cherche dans mes sources"); // La langue par défaut
    }
  }, [selectedLanguage]);

  return (
    <div className="overflow-y-auto  h-full md:px-12 mt-6" ref={chatBoxRef}>
      {messages.length === 0 && (
        <Welcome
          selectedPlatformId={selectedPlatformId}
          setSelectedPlatformId={setSelectedPlatformId}
        />
      )}

      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          isLastMessage={index === messages.length - 1}
          lastUserMessage={getLastUserMessage(messages, index)}
          audioOn={audioOn}
          selectedLanguage={selectedLanguage}
          onDataFromChild={onDataFromChild}
          handleIsWriting={handleIsWriting}
          loader={loading}
        />
      ))}
      {loading && (
        <div className="text-left mx-auto w-11/12 border-t-2 border-[#D0D3DB]">
          <div className={`mx-2 my-2 border-[#D0D3DB] relative w-full`}>
            <img
              src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/picto-envoyer.png"
              alt=""
              className="mx-1 my-2 absolute top-0 -left-5"
              style={{ width: "30px", height: "30px" }} // Sets the size of the image to 30x30 pixels
            />
            <div className="flex-col py-2 pl-6 mb-5 w-full">
              <div className="flex w-full">
                <span className="text-base font-bold block align-middle whitespace-nowrap">
                  Victor IA
                </span>
              </div>
              <img
                src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/loader.gif"
                alt=""
                className=" mx-1 my-2 inline-block w-8 h-8 "
              />
              <span>{loaderText} ...</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
