const platforms = {
  com: {
    id: "com",
    title: "Programme local général",
    src: "https://cbimagesicons.blob.core.windows.net/chat-icons/Logonew-retina.png",
  },
  mons: {
    id: "mons",
    title: "Programme de Mons en mieux",
    src: "https://cbimagesicons.blob.core.windows.net/chat-icons/MEM-LogoRVB-positif-color-300x159.png",
  },
};

export default platforms;
