// Welcome.js

import React from "react";
import { InlinePlatformSelector } from "./PlatformSelector";

const Welcome = ({ selectedPlatformId, setSelectedPlatformId }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full mx-4">
      {/* <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/mr+logo+(3).png"
        alt="Welcome"
        className="h-10 w-auto md:h-20 mb-5"
      /> */}
      <div className="flex flex-col mx-5 gap-2 max-w-prose">
        <h1 className="font-bold text-xl">Victor IA</h1>
        <div className="hidden md:block">
          {/* <p className="text-l ml-5 mr-5">
          Dans 48h, vous pourrez me poser toutes vos questions relatives au
          programme local général du MR. MR à la région, MR à la commune, la
          cohérence ça change tout.
          </p> */}
          <p className="font-bold leading-tight">
            Posez-moi dès maintenant vos questions sur le programme communal
            défendu par le MR à Mons. Il n'y a pas besoin d'intelligence
            artificielle pour comprendre que Mons en Mieux c'est vraiment mieux
            pour Mons !
          </p>
        </div>
        {/* <p className="font-bold">Posez-moi toutes vos questions !</p> */}
      </div>
      <InlinePlatformSelector
        selectedPlatformId={selectedPlatformId}
        setSelectedPlatformId={setSelectedPlatformId}
      />
    </div>
  );
};

export default Welcome;
// Welcome.js
